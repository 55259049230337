@layer components {
  .sticky-navbar {
    @apply !fixed !border-b !bg-opacity-80 !backdrop-blur-sm dark:!bg-opacity-80;
  }

  .sticky-navbar .menu-horizontal > a {
    @apply lg:py-[21px];
  }

  .navbar {
    @apply py-0;
  }
}
