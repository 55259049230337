/* components/typography.css */

@layer components {
  /* Typography Helpers */
  .pg-title {
    @apply text-3xl text-black dark:text-white font-semibold mb-4;
  }

  .pg-subtitle {
    @apply text-2xl text-black dark:text-white font-medium mb-2;
  }

  .pg-subtitle-small {
    @apply text-black dark:text-white font-normal;
  }

  .pg-link {
    @apply text-base text-primary hover:underline sm:text-right;
  }

  .pg-text-centered {
    @apply text-center;
  }

  .pg-text-left {
    @apply text-left;
  }

  .pg-text-right {
    @apply text-right;
  }

  .pg-help {
    @apply text-gray-600 text-sm dark:text-gray-400;
  }

  .pg-text-primary {
    @apply text-primary;
  }

  .pg-text-success {
    @apply text-success;
  }

  .pg-text-info {
    @apply text-info;
  }

  .pg-text-danger {
    @apply text-error;
  }

  .pg-text-muted {
    @apply text-gray-700;
  }
}
