@layer components {
  #togglePlan:checked ~ span .dot {
    @apply translate-x-[26px];
  }

  #subscription-details-table {
    /* Optional container styles */
  }
  
  /* Subscription Detail */
  .subscription-detail {
    @apply flex flex-row items-start m-2;
  }
  
  /* Subscription Detail Label */
  .subscription-detail-label {
    @apply w-64 text-right mr-4;
  }
  
  /* Subscription Detail Value */
  .subscription-detail-value {
    @apply w-full;
  }
  
  /* Subdetail */
  .subdetail {
    @apply text-sm mt-1;
    /* Optional: Override with exact values if needed */
    /* font-size: 0.8rem; */
    /* margin-top: 0.2rem; */
  }
}
