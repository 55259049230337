/* components/forms.css */

@layer components {
  /* Forms */
  .pg-input-group {
    @apply mb-3;
  }

  .pg-label {
    @apply mb-[10px] block text-sm text-black dark:text-white;
  }

  .pg-input-text,
  .pg-textarea {
    @apply w-full rounded-md border border-stroke bg-white py-3 px-6 text-base font-medium text-body outline-none focus:border-primary focus:shadow-input dark:border-stroke-dark dark:bg-black dark:text-white dark:focus:border-primary;
  }

  .pg-select select {
    @apply select select-bordered w-full;
  }

  .pg-bg-danger {
    @apply mb-4 p-4 text-red-700 bg-red-100 border border-red-300 rounded-md;
  }

  .pg-bg-success {
    @apply mb-4 p-4 text-green-700 bg-green-100 border border-green-300 rounded-md;
  }

  /* Make dark mode placeholder text less visible */
  input::placeholder,
  textarea::placeholder {
    @apply dark:text-gray-500;
  }
}
