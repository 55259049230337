/* components/layout.css */

@layer components {
  /* Columns */
  .pg-columns {
    @apply flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0;
  }

  .pg-columns-reversed {
    @apply lg:flex-row-reverse;
  }

  .pg-column {
    @apply flex-1;
  }

  .pg-column-one-quarter {
    @apply basis-1/4 grow-0 shrink-0;
  }

  .pg-column-one-third {
    @apply basis-1/3 grow-0 shrink-0;
  }

  .pg-align-items-center {
    @apply items-center;
  }
}
