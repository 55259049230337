/* components/buttons.css */

@layer components {
  /* Buttons */
  /* Base Button Styles */
  .pg-button-base {
    @apply cursor-pointer inline-block rounded-md py-[10px] px-11 text-base font-medium;
  }

  /* Primary Button */
  .pg-button-primary {
    @apply pg-button-base bg-primary text-white hover:bg-opacity-90;
  }

  /* Secondary Button */
  .pg-button-secondary {
    @apply pg-button-base border border-primary text-primary hover:bg-primary hover:text-white;
  }

  .pg-button-danger {
    @apply pg-button-base border border-red-500  hover:text-white  text-red-500 hover:bg-red-500 hover:border-red-700;
  }
}
