/* components/breadcrumbs.css */

@layer components {
  /* Breadcrumbs */
  .pg-breadcrumbs {
    @apply flex items-center;
  }

  .pg-breadcrumbs a {
    @apply pg-link;
  }

  .pg-breadcrumbs li:first-child {
    @apply p-0;
  }

  .pg-breadcrumbs li:not(:first-child) {
    @apply pl-3;
  }

  .pg-breadcrumbs li:not(:first-child)::before {
    @apply text-gray-500 content-['/'] pr-3;
  }

  .pg-breadcrumb-active {
    @apply text-gray-500;
  }
}
