@layer components {
  .faq .faq-btn::after {
    content: "+";
    @apply text-lg font-semibold text-black dark:text-white;
  }

  .faq.active .faq-btn::after {
    content: "-";
  }

  .faq .faq-content {
    @apply max-h-0 py-0 opacity-0 transition-all duration-100 ease-in-out;
  }

  .faq.active .faq-content {
    @apply max-h-fit py-[30px] opacity-100;
  }
}
