/* components/tables.css */

@layer components {
  /* Tables */
  /*.pg-table {
    @apply min-w-full bg-white border border-gray-200;
  }*/

  /* Table Container */
  .pg-table {
    @apply min-w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700;
  }

  /* Table Header */
  .pg-table-header {
    @apply px-6 py-3 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-700 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider;
  }

  /* Table Row */
  .pg-table-row {
    @apply hover:bg-gray-100 dark:hover:bg-gray-700;
  }

  /* Table Data Cell */
  .pg-table-cell {
    @apply px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100;
  }

  /* Responsive Table Wrapper */
  .table-responsive {
    @apply overflow-x-auto;
  }
}
