/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");*/
@import "./fonts/google-fonts.css";

/* Base Layer */
@import "./base.css";

/* Third Party Librairies */
@import "./libs/select2.css";

/* Component Layer */
@import "tailwindcss/components";

@import "./components/typography.css";
@import "./components/buttons.css";
@import "./components/forms.css";
@import "./components/layout.css";
@import "./components/tables.css";
@import "./components/breadcrumbs.css";
@import "./components/cards.css";
@import "./components/icons.css";
@import "./components/flag-icons.css";
@import "./components/faq.css";
@import "./components/navbar.css";
@import "./components/code-block.css";
@import "./components/term-agreement.css";
@import "./components/subscription-plan.css";
@import "./components/container.css";
@import "./components/spinner.css";
@import "./components/menu.css";

@import "./components/alerts.css";

/* Base utilities layer */
@import "tailwindcss/utilities";
