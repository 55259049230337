@layer components {
    .menu-item {
        @apply
          text-base
          text-black dark:text-white
          hover:bg-gray-100
          active:bg-gray-100
          active:text-black
          focus:bg-gray-100
          focus:text-black
          dark:hover:bg-primary
          dark:hover:text-white
          dark:active:bg-primary
          dark:focus:bg-primary !important;
          
      }
  }